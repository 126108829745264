export var formData =
  {
    bankAccount: '', // 银行账号
    text1: '', // 银行名称
    bz: '', // 备注
    companyName: '', // 公司名称11
    planEndTime: '', // 计划时间至
    planMoney: '', // 计划资金
    planStartTime: '', // 计划时间从11
    chooseBankdata: {}
  }
export var rules = {
  companyName: [
    { required: true, message: '请选择公司名称', trigger: 'change' }
  ],
  planStartTime: [
    { required: true, message: '请选择时间', trigger: 'change' }
  ],
  planEndTime: [
    { required: true, message: '请选择时间', trigger: 'blur' }
  ],
  text1: [
    { required: true, message: '请选择账户', trigger: 'blur' }
  ],
  planMoney: [
    { required: true, message: '请填写计划资金', trigger: 'change' }
  ],
  bankAccount: [
    { required: true, message: '请选择银行账号', trigger: 'change' }
  ]
}
