<template>
<!-- 对账信息 -->
    <div class="mainform">
        <div class="form">
            <el-form  class="content bascform" ref="addpayplan" :model="form" :rules="rules" label-width="120px">
            <div class="col col4">
              <el-form-item label="公司" prop="companyName">
                  <el-select size="small" filterable remote
                    :remote-method="requestCompanyOption"
                    :loading="companyLoading"
                    v-model="form.companyName" placeholder="编码/名称">
                    <el-option v-for="(item) in companyOptions"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                  </el-select>
              </el-form-item>
            </div>
            <div  class="col col4" >
                <el-form-item label="计划时间从" prop="planStartTime">
                    <el-date-picker  @change="timeForm" v-model="form.planStartTime" type="date"></el-date-picker>
                </el-form-item>
            </div>
            <div  class="col col4" >
                <el-form-item label="银行名称" prop="text1">
                    <el-input v-model="form.text1" >
                      <template #append>
                         <el-button type="text" @click="chooseBtn" >选择账户</el-button>
                      </template>
                    </el-input>
                </el-form-item>
            </div>
            <div  class="col col4">
                <el-form-item label="计划时间至" prop="planEndTime">
                    <el-date-picker  @change="timeFormEnd" v-model="form.planEndTime" type="date"></el-date-picker>
                </el-form-item>
            </div>
            <div  class="col col4">
                <el-form-item label="银行账号" prop="bankAccount">
                    <el-input v-model="form.bankAccount"></el-input>
                </el-form-item>
            </div>
            <div  class="col col4">
                <el-form-item label="计划资金" prop="planMoney">
                    <el-input v-model="form.planMoney"></el-input>
                </el-form-item>
            </div>
            <div class="col col4">
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="form.bz"></el-input>
                </el-form-item>
            </div>
            </el-form>
        </div>
        <dialog-box  ref="addDialog" dialogWidth="750px" :bankData="bankData" :dialogShow="dialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd"  title="选择银行账户" componentName="addpayPlandialog"
        :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
        </dialog-box>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { formData, rules } from './js/config'
import { baseURL, request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import axios from 'axios'
import DialogBox from '@/components/plug/DialogBox.vue'
export default {
  name: 'PayPlanAdddialog',
  components: { ...Component, DialogBox },
  data: function () {
    return {
      form: { ...formData },
      rules: rules,
      companyOptions: [],
      companyLoading: false,
      dialogShow: false,
      bankShow: false, // 选择账户
      companyCode: '',
      bankData: []
    }
  },
  methods: {
    requestCompanyOption (val) { // 公司
      this.companyLoading = true
      this.companyOptions = []
      axios.post(`${baseURL}/api/sap/getCompanyCodeInfo`, val, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          token: localStorage.getItem('token')
        }
      }).then((Response) => {
        if (Response.data.code === 200) {
          for (const item of Response.data.data) {
            this.companyOptions.push({ key: `${item.companyCode}-${item.companyName}`, value: `${item.companyCode}-${item.companyName}` })
            this.companyCode = item.companyCode
          }
        } else {
          this.$message({
            message: Response.data.msg,
            type: 'error'
          })
        }
        this.companyLoading = false
      })
    },
    handleClose () {
      this.dialogShow = false
    },
    confirmAdd () { // 选择银行账户弹框的确认按钮
      var chooseData = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
      if (chooseData.length > 0) {
        this.form.chooseBankdata = chooseData
        this.form.chooseBankdata.forEach(item => {
          this.form.text1 = item.text1 // 银行名称
          this.form.bankName = item.text1 // 银行名称
          this.form.bankAccount = item.bnkn2 // 银行账户
        })
        this.bankShow = true
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的银行账户',
          type: 'warning'
        })
      }
    },
    chooseBtn () { // 选择账户的弹框
      this.dialogShow = true
      var bukrs = this.companyCode
      request('/api/sap/bankAccount/getByBukrs', 'POST', bukrs, 'application/json').then((res) => {
        if (res.code === 200) {
          this.bankData = res.data
        }
      })
    },
    // 选择日期转换格式
    timeForm () {
      if (this.form.planStartTime) {
        this.form.planStartTime = dayjs(this.form.planStartTime).format('YYYY-MM-DD')
      } else {
        this.form.planStartTime = ''
      }
    },
    timeFormEnd () {
      if (this.form.planEndTime > new Date(this.form.planStartTime)) {
        if (this.form.planEndTime) {
          this.form.planEndTime = dayjs(this.form.planEndTime).format('YYYY-MM-DD')
        }
      } else {
        this.$message({
          message: '结束日期不能小于开始日期',
          type: 'error'
        })
        this.form.planEndTime = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
}
</style>
